
import React from 'react'
import Header from '../../components/header/Header.jsx'
import Footer from '../../components/footer/Footer.jsx'
import NavbarHome from '../../components/navbarHome/NavbarHome.jsx'
function Home() {
  return (
    <>
      <Header>
      </Header>
      <NavbarHome/>
     <Footer></Footer>
    </>

  )
}

export default Home